<template>
  <div class="inspect-log-wrapper">
    <d-search class="d-search"
              :searchData="searchData"
              @searchEvent="searchEvent" @exportEvent="exportEvent"/>
    <div class="content">
      <d-table :columnData="columnData"
               :tableData="tableData"></d-table>
      <d-paging :pager="pager"
                style="marginTop:50px;"
                @change="searchEvent" />
    </div>
  </div>
</template>

<script>
import DSearch from "../../../components/d-search";
import DTable from "../../../components/d-table";
import DPaging from "../../../components/d-paging";
import {onDownload} from "../../../utils/utils";

export default {
  name: "inspectLog",
  components: {
    DPaging,
    DTable,
    DSearch
  },
  data(){
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      searchData: {
        searchItem: [
          {
            label: "类型",
            value: "type",
            type: "commonSelect",
            placeholder: "",
            options: []
          },
          {
            label: '核验日期',
            value: 'time',
            type: 'commonDatetime',
            startPlaceholder: "开始日期",
            endPlaceholder: "结束日期"
          },
          {
            label: '店铺',
            value: 'shopNo',
            type: 'linkageSelect',
            dateType: 'month',
            placeholder: '',
            options: [],
            optionProps: {
              value: 'name',
              label: 'name',
              children: 'childList'
            }
          }, {
            label: '状态',
            value: 'status',
            type: 'commonSelect',
            placeholder: '',
            options: [
              {
                label: '通过',
                value: 1,

              },
              {
                label: '驳回',
                value: 2
              }
            ],
          }
        ],
        searchBtn: {
          queryBtn: true,
          exportBtn:true
        },
        params: {
          status: null,
          time:['','']
        }
      },
      columnData: [
        { prop: "status", label: "状态" },
        { prop: "type", label: "类型" },
        { prop: "shopName", label: "店铺名称" },
        { prop: "shopNo", label: "店铺号" },
        { prop: "committerName", label: "提交人" },
        { prop: "guardName", label: "核验人" },
        { prop: "checkTime", label: "核验时间" },
      ],
      tableData: []
    }
  },
  mounted(){
    this.getShopList();
    this.getType();
    this.searchEvent()
  },
  methods:{
    // 查询
    searchEvent(){
      let requestParam = this.getParams();
      this.$api.mentapi.inspectList(requestParam).then(res=>{
        if (res.code === 200) {
          this.pager.count = res.data.total
          this.tableData = res.data.list.map(item => ({
            ...item,
            status: item.status == 1 ? '通过' : '驳回'
          }))
        }
      })
    },

    exportEvent(){
      this.$api.mentapi.inspectExport(Object.assign(this.getParams(),{pageNum:1,pageSize:10000})).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
          .then(({ data, code }) => {
            if (code == 200) {
              this.childList = data.childList
              const child = [{
                id: data.id,
                name: data.name,
                childList: data.childList
              }]
              this.searchData.searchItem.forEach(ele => {
                if (ele.type === 'linkageSelect') {
                  ele.options = child
                }
              })
            }
          })
    },

    getType(){
      this.$api.mentapi.inspectType().then(({ data, code }) => {
        if (code == 200) {
          this.searchData.searchItem.forEach(ele => {
            if (ele.type === 'commonSelect'&& ele.value=== "type") {
              ele.options = data.map(e=>{
                return {
                  label:e,
                  value:e
                }
              })
            }
          })
        }
      })
    },

    getParams(){
      let selectShopNo = this.searchData.params.shopNo && this.searchData.params.shopNo.length > 0 ?
                          this.searchData.params.shopNo[this.searchData.params.shopNo.length - 1]:''
      let _shopNo = selectShopNo.slice(selectShopNo.indexOf('(')+1,-1)
      return {
        startDate:(this.searchData.params.time&&this.searchData.params.time[0])||'',
        endDate:(this.searchData.params.time&&this.searchData.params.time[1])||'',
        shopNo:_shopNo,
        status:this.searchData.params.status,
        type:this.searchData.params.type,
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.inspect-log-wrapper {
  width: 100%;
  .content {
    .el-table {
      margin-bottom: 20px;
      flex: 1;
      overflow-y: auto;
    }
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 0 30px;
    text-align: center;
  }
}
</style>